import { SYSTEM_LIMITS } from 'common';

const { IMAGE: imageLimits } = SYSTEM_LIMITS;

export const getImageScalesOnUpload = (image) => {
  const imageObject = new Image();
  imageObject.src = URL.createObjectURL(image);

  const loadImage = new Promise((resolve) => {
    imageObject.onload = () => {
      const { height, width } = imageObject;
      resolve({ height, width });
    };
  });

  return loadImage;
};

export const convertByteToMb = (bytes) => (bytes * 0.000001).toFixed(1);

export const getImageLimitByType = (type) =>
  ({
    profileImageSection: imageLimits.profileImage,
    coverPhotoSection: imageLimits.coverImage,
    backgroundImageSection: imageLimits.backgroundImage,
  }[type]);

export const validateImageSizeOnUpload = async (img, pictureType) => {
  const scales = await getImageScalesOnUpload(img);
  const scaleLimit = getImageLimitByType(pictureType);
  const imageSize = +convertByteToMb(img.size);

  if (!scales.width || !pictureType || !img.size) {
    return { isValid: false, message: 'Something went wrong' };
  }
  if (scales.width < scaleLimit) {
    return { isValid: false, message: `Image must be at least ${scaleLimit} pixels` };
  }
  if (imageSize > imageLimits.size) {
    return { isValid: false, message: `Image must be less than ${imageLimits.size} MB` };
  }

  return { isValid: true, message: '' };
};
