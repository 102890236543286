import { Link } from 'react-router-dom';
import { ENTITY_TYPES } from 'common';

import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import SearchDropdown from '../SearchDropdown';
import DropDownFilter from '../SearchDropdown/DropdownFilter';
import Actions from './Actions';

const { DEPARTMENT } = ENTITY_TYPES;

const coursePath = (item) => `/${DEPARTMENT.key}/${item.id}/${item.urlName}/organizer/homepage`;

export const getDepartmentsColumns = ({ setCourseUserData, dispatch, deleteDepartment, updateArchive }) => [
  {
    title: <SearchDropdown title='Name' name='name' />,
    dataIndex: 'name',
    key: 'name',
    render: (_index, item) => <Link onClick={() => setCourseUserData(item, coursePath)}>{item.name}</Link>,
  },
  {
    title: <Title variant={5}>Administrator</Title>,
    dataIndex: 'administratorName',
    key: 'administratorName',
  },
  {
    title: <DropDownFilter name='accessTypeId' firstParam='Private' secondParam='Public' />,
    dataIndex: 'accessTypeId',
    key: 'accessTypeId',
  },
  {
    title: <Title variant={5}>Pricing</Title>,
    dataIndex: 'paymentTypeId',
    key: 'paymentTypeId',
    render: (_index, item) => (
      <>
        {item.paymentTypeId && (
          <Text $variant={3} paragraph>
            Type: {item.paymentTypeId}
          </Text>
        )}

        {item.price && (
          <Text $variant={3} paragraph>
            Price: ${item.price}
          </Text>
        )}
      </>
    ),
  },

  {
    title: <Title variant={5}>Archived</Title>,
    dataIndex: 'isArchive',
    key: 'isArchive',
    render: (_index, item) => <Text>{item.isArchive === true ? 'Archived' : ''}</Text>,
  },
  {
    title: '',
    dataIndex: 'Actions',
    key: 'Actions',
    render: (_index, item) => (
      <Actions
        item={item}
        setCourseUserData={setCourseUserData}
        dispatch={dispatch}
        deleteDepartment={deleteDepartment}
        updateArchive={updateArchive}
      />
    ),
  },
];
export const COURSES_CONST = { NAME: 'name', PRICE: 'price', COURSES: 'courses', LIBRARIES: 'libraries' };
