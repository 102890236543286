import styled from 'styled-components';

const AssessmentWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  .card-list-box {
    box-sizing: border-box;
    padding-bottom: 5px;
    &.assessment-list {
      .content-overflow {
        height: 100%;
      }
    }
  }

  .empty-data {
    padding-top: 96px;
    width: 158px;
    box-sizing: border-box;
    margin: 0 auto;
    .add-question-button {
      width: 100%;
      margin-top: 56px;
    }
    .button-children {
      justify-content: center;
    }
    img {
      margin-left: 36px;
      width: 110px;
      height: 112px;
    }
  }

  .question-icon-wrapper {
    position: absolute;
    bottom: 40px;
    right: 28px;
  }
  .helpTooltip {
    z-index: -1;
  }
`;

export default AssessmentWrapper;
