// TODO: React Router Dom 6 does not support regex paths but adds this feature later. Check every 2 weeks https://github.com/remix-run/react-router/discussions/8132

const PATHS = {
  HOME: '/',
  ACTIVATE_ACCOUNT: '/activate-account',
  PROFILE: '/user/:userName',
  EXPLORE: '/explore/:category',
  EXPLORE_LEARNING_COMMUNITIES: '/explore/categories/learning-communities',
  EXPLORE_COLLECTIONS: '/explore/categories/collections',
  EXPLORE_FAVORITES: '/explore/categories/favorites',
  EXPLORE_MEMBERS: '/explore/categories/members',
  EXPLORE_ORGANIZATIONS: '/explore/categories/organizations',
  DASHBOARD: '/dashboard/:role/:category',
  ADMIN: '/admin',
  ADMIN_PAGE_CATEGORY: ':category',
  ADD_COMPANY: ':domainId',
  SUBMISSIONS: '/submissions',
  NOT_FOUND: '/404',

  SIGN_UP: '/sign-up',
  SIGN_IN: '/sign-in',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset/:token',
  VERIFY_EMAIL: '/verify/:token',
  // footer
  ABOUT: 'about',
  SERVICES: 'services',
  REFOUND_POLICY: 'refund-policy',

  // entities
  LEARNING_COMMUNITY_MANAGER_ENTITIES: '/learning-communities/manage/edit-settings/:id/:urlName',
  DEPARTMENT_MANAGER_ENTITIES: '/collections/manage/edit-settings/:id/:urlName',
  ORGANIZATION_MANAGER_ENTITIES: '/organizations/manage/edit-settings/:id/:urlName',

  LEARNING_COMMUNITY_CREATE_ENTITIES: '/learning-communities/:id/manage/create/:urlName/:section',

  DEPARTMENT_CREATE_ENTITIES: '/collections/:id/manage/create/:urlName/:section',
  ORGANIZATION_CREATE_ENTITIES: '/organizations/:id/manage/create/:urlName/:section',

  EDIT_ENTITIES: '/:entities/manage/edit-settings/:id/:urlName',
  CREATE_ENTITiES_PAYMENT_METHOD: '/:entities/manage/audience-settings/:id/:urlName',
  LEARNING_COMMUNITY: `/:entities/:id/:urlName/:role`,

  LEARNING_COMMUNITY_CATEGORY: ':chapterId/:category',
  LEARNING_COMMUNITY_ASSESSMENT: ':chapterId/:category/preview/:testNumber',
  LEARNING_COMMUNITIES_DASHBOARD: '/dashboard/organizer/learning-communities/new',
  LEARNING_COMMUNITIES_MEMBER_DASHBOARD: '/dashboard/member/learning-communities/new',
  COLLECTIONS_MEMBER_DASHBOARD: '/dashboard/member/collections/new',
  ORGANIZATIONS_MEMBER_DASHBOARD: '/dashboard/member/organizations/new',
  LEARNING_COMMUNITIES_INVITATIONS_DASHBOARD: '/dashboard/member/invited/new',
  COLLECTIONS_DASHBOARD: '/dashboard/organizer/collections/new',
  ORGANIZATIONS_DASHBOARD: '/dashboard/organizer/organizations/new',
  LEARNING_COMMUNITY_CATEGORY_EDIT_QUESTION: ':chapterId/:category/:questionId',

  LEARNING_COMMUNITY_HOMEPAGE: '/learning-communities/:id/:urlName/:role/:tab',

  ORGANIZATION_HOMEPAGE: '/organizations/:id/:urlName/:role/:tab',
  DEPARTMENT_HOMEPAGE: '/collections/:id/:urlName/:role/:tab',

  NOTIFICATIONS: '/:userName/notifications',
  NOTIFICATIONS_SETTINGS_PAGE: '/:userName/notificationsSettings',
  SUBMISSION_REVIEW: 'submission/review/:courseId/:versionId',
  INVITED_USER_LEARNING_COMMUNITY: '/dashboard/member/invited',
};

export default PATHS;
