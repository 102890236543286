import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useGetAdminStaticPagesQuery } from 'store/services/superAdmin/AdminStaticPages';

import Pagination from 'style-guide/Pagination';
import Table from 'style-guide/Table';

import { getDomainTracking } from 'configs/localStorage';

import { getStaticPagesColumns } from './columns';

import params from '../helper/helperFunction';

const SuperAdminStaticPages = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const domainId = getDomainTracking().id;

  const { data: staticPagesList } = useGetAdminStaticPagesQuery(params({ page, domainId, searchParams }));

  return (
    <div>
      <Table
        columns={getStaticPagesColumns({
          dispatch,
        })}
        data={staticPagesList?.rows}
        rowKey={(row) => row.id}
      />
      <div className='mt--24'>
        <Pagination total={staticPagesList?.count || 0} current={page} onChange={setPage} className='pagination' />
      </div>
    </div>
  );
};

export default SuperAdminStaticPages;
