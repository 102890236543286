import { Link } from 'react-router-dom';
import { ENTITY_TYPES } from 'common';
import Text from 'style-guide/Typography/Text';
import SearchDropdown from '../SearchDropdown';
import DropDownFilter from '../SearchDropdown/DropdownFilter';
import Actions from './Actions';

const { ORGANIZATION } = ENTITY_TYPES;

const coursePath = (item) => `/${ORGANIZATION.key}/${item.id}/${item.urlName}/organizer/homepage`;

export const getOrganizationsColumns = ({ setCourseUserData, dispatch, deleteDepartment, updateArchive }) => [
  {
    title: <SearchDropdown title='Name' name='name' />,
    dataIndex: 'name',
    key: 'name',
    render: (_index, item) => <Link onClick={() => setCourseUserData(item, coursePath)}>{item.name}</Link>,
  },
  {
    title: 'Administrator',
    dataIndex: 'administratorName',
    key: 'administratorName',
  },
  {
    title: <DropDownFilter name='accessTypeId' firstParam='Private' secondParam='Public' />,
    dataIndex: 'accessTypeId',
    key: 'accessTypeId',
  },
  {
    title: 'Payment Type',
    dataIndex: 'paymentTypeId',
    key: 'paymentTypeId',
  },
  {
    title: <SearchDropdown title='Price' name='price' />,
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_index, item) => <Text>{item.status === true ? '___' : ''}</Text>,
  },
  {
    title: 'Archive',
    dataIndex: 'isArchive',
    key: 'isArchive',
    render: (_index, item) => <Text>{item.isArchive === true ? 'Archived' : ''}</Text>,
  },
  {
    title: '',
    dataIndex: 'Actions',
    key: 'Actions',
    render: (_index, item) => (
      <Actions
        item={item}
        setCourseUserData={setCourseUserData}
        dispatch={dispatch}
        deleteDepartment={deleteDepartment}
        updateArchive={updateArchive}
      />
    ),
  },
];

export const COURSES_CONST = {
  NAME: 'name',
  PRICE: 'price',
  COURSES: 'courses',
  LIBRARIES: 'libraries',
  ORGANIZATIONS: 'organizations',
};
