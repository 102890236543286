import { ENTITY_TYPES } from 'common';
import PATHS from 'constants/paths';
import { generatePath } from 'react-router-dom';

const userMenu = (user) => [
  {
    name: 'Explore',
    submenu: [
      {
        name: ENTITY_TYPES.LEARNING_COMMUNITY.singular,
        path: PATHS.EXPLORE_LEARNING_COMMUNITIES,
      },
      {
        name: ENTITY_TYPES.DEPARTMENT.singular,
        path: PATHS.EXPLORE_COLLECTIONS,
      },
      {
        name: ENTITY_TYPES.ORGANIZATION.singular,
        path: PATHS.EXPLORE_ORGANIZATIONS,
      },
    ],
    renderDivider: false,
  },
  {
    name: 'Member',
    path: PATHS.LEARNING_COMMUNITIES_MEMBER_DASHBOARD,
    renderDivider: false,
  },
  {
    name: 'Organizer',
    path: PATHS.LEARNING_COMMUNITIES_DASHBOARD,
    renderDivider: true,
  },

  {
    name: 'Profile',
    path: generatePath(PATHS.PROFILE, { userName: user?.userName }),
    renderDivider: false,
  },
  {
    name: 'Members',
    path: generatePath(PATHS.EXPLORE, { category: ENTITY_TYPES.MEMBER.key }),
    renderDivider: true,
  },
];

export default userMenu;
