import moment from 'moment';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import noData from 'assets/images/noData.png';

import { Check } from 'style-guide/Icons';
import Clock from 'style-guide/Icons/Clock';
import Image from 'style-guide/Image';
import { MenuItem } from 'style-guide/Menu';
import Text from 'style-guide/Typography/Text';
import UserAvatar from 'style-guide/UserAvatar';

import classNames from 'classnames';
import PATHS from 'constants/paths';
import { getUserData } from 'store/selectors/user';
import { useUpdateNotificationMutation } from 'store/services/notifications';
import Button from 'style-guide/Button';
import MenuWrapper from './style/MenuWrapper';
import NotificationMenuWrapper from './style/NotificationMenuWrapper';

const NotificationMenu = ({ data }) => {
  const { userName } = useSelector(getUserData) || {};
  const [updateNotifications] = useUpdateNotificationMutation();

  const navigate = useNavigate();

  const updateNotificationToRead = (notifications) => {
    if (!notifications.read) {
      updateNotifications({ id: notifications.id, read: !notifications.read });
    }
    navigate(notifications.url);
  };

  return (
    <NotificationMenuWrapper>
      {data?.notifications?.length ? (
        <MenuWrapper className={classNames({ 'empty-state': !data?.notifications.length })}>
          {data.notifications.map((e) => (
            <MenuItem key={e.id} onClick={() => updateNotificationToRead(e)}>
              <div className='row align-items-center flex-column flex-sm-row'>
                <div className='col d-flex align-items-center'>
                  <UserAvatar userName={e.userName} picture={e.profileImage} />
                  <Text className='notification-content'>{e.text}</Text>
                </div>
                <div className='col d-flex justify-content-end align-items-center full-height'>
                  <Text $varian={1}>{moment(e.createdAt).fromNow()}</Text>
                  {!e.read ? (
                    <Check
                      className='check-icon'
                      data-testid='check-svg'
                      onClick={(event) => {
                        event.stopPropagation();
                        updateNotifications({ archived: e.archived, id: e.id, read: !e.read });
                      }}
                    />
                  ) : null}
                  <Clock
                    className='clock-icon'
                    data-testid='clock-svg'
                    onClick={(event) => {
                      event.stopPropagation();
                      updateNotifications({ archived: !e.archived, id: e.id, read: e.read });
                    }}
                  />
                </div>
              </div>
            </MenuItem>
          ))}
        </MenuWrapper>
      ) : (
        <div className='d-flex align-items-center justify-content-center empty-state-box'>
          <Image src={noData} />
        </div>
      )}
      <Button variant='link' to={generatePath(PATHS.NOTIFICATIONS, { userName })} $block className='see-more'>
        See more
      </Button>
    </NotificationMenuWrapper>
  );
};

export default NotificationMenu;
