import { Link } from 'react-router-dom';
import { ENTITY_TYPES } from 'common';

import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import SearchDropdown from '../SearchDropdown';
import DropDownFilter from '../SearchDropdown/DropdownFilter';
import Actions from './Actions';

const { LEARNING_COMMUNITY } = ENTITY_TYPES;

export const COURSES_CONST = { NAME: 'name', PRICE: 'price', COURSES: 'courses' };
const coursePath = (item) => `/${LEARNING_COMMUNITY.key}/${item.id}/${item.urlName}/organizer/homepage`;

export const getColumns = ({ setCourseUserData, dispatch, deleteCourse, updateArchive, updateDemo }) => [
  {
    title: <SearchDropdown title='Name' name='name' s />,
    dataIndex: 'name',
    key: 'name',
    render: (_index, item) => <Link onClick={() => setCourseUserData(item, coursePath)}>{item.name}</Link>,
  },
  {
    title: <Title variant={5}>Administrator</Title>,
    dataIndex: 'administratorName',
    key: 'administratorName',
  },
  {
    title: <DropDownFilter name='accessTypeId' firstParam='Private' secondParam='Public' />,
    dataIndex: 'accessTypeId',
    key: 'accessTypeId',
  },
  {
    title: <Title variant={5}>Payment Type</Title>,
    dataIndex: 'paymentTypeId',
    key: 'paymentTypeId',
  },
  {
    title: <SearchDropdown title='Price' name='price' />,
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: <Title variant={5}>Archive</Title>,
    dataIndex: 'isArchive',
    key: 'isArchive',
    render: (_index, item) => (
      <div className='row'>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <Text>{item.isArchive === true ? 'Archived' : ''}</Text>
        </div>
      </div>
    ),
  },
  {
    title: '',
    dataIndex: 'Actions',
    key: 'Actions',
    render: (_index, item) => (
      <div className='d-flex align-items-center'>
        <Actions
          item={item}
          setCourseUserData={setCourseUserData}
          dispatch={dispatch}
          deleteCourse={deleteCourse}
          updateArchive={updateArchive}
          updateDemo={updateDemo}
        />
      </div>
    ),
  },
];
