import { useEffect } from 'react';
import CheckBox from 'style-guide/CheckBox';
import Text from 'style-guide/Typography/Text';
import { LANGUAGE_ITEMS } from './helper';
import DropdownLanguagesWrapper from './style/DropdownLanguagesWrapper';

const DropdownLanguages = ({ register, setValue, watch, languages }) => {
  const languageValues = watch('languages') || {};
  const isAllSelected = Object.values(languageValues).every(Boolean);

  const handleSelectAll = () => {
    LANGUAGE_ITEMS.forEach((item) => {
      setValue(`languages.${item.languageCode}`, !isAllSelected);
    });
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (languages) {
      const initialValues = languages.split(',').reduce((acc, element) => {
        acc[element] = true;
        return acc;
      }, {});
      LANGUAGE_ITEMS.forEach((item) => {
        setValue(`languages.${item.languageCode}`, initialValues[item.languageCode] || false);
      });
    }
  }, [languages, setValue]);

  return (
    <DropdownLanguagesWrapper className='ph--24 pv--8'>
      <div className='d-flex flex-column mb--16'>
        <Text $variant={1}>Default language</Text>
        <Text>English (US)</Text>
      </div>
      <div className='d-flex mb--16 align-items-center' onClick={handleCheckboxClick}>
        <CheckBox {...register('selectAll')} onChange={handleSelectAll} checked={isAllSelected} />
        <Text className='ml--8'>Select all</Text>
      </div>
      {LANGUAGE_ITEMS.map((item) => (
        <div className='d-flex mb--16 align-items-center' key={item.languageCode} onClick={handleCheckboxClick}>
          <CheckBox
            {...register(`languages.${item.languageCode}`)}
            checked={languageValues[item.languageCode] || false}
          />
          <Text className='ml--8'>{item.value}</Text>
        </div>
      ))}
    </DropdownLanguagesWrapper>
  );
};

export default DropdownLanguages;
