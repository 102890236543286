import emptySplitApi from '..';

const imageApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    uploadCoverImage: build.mutation({
      query: (params) => ({
        url: `/amazon-s3?folder=${params.folder}&${params.table}=${params.id}`,
        method: 'POST',
        body: params.data,
      }),
      // to do: We should stop using global tags hare
      invalidatesTags: (_result, _err, arg) => {
        if (arg.ignoreInvalidateTags) return [];

        switch (arg.table) {
          case 'libraries':
            return ['department'];
          case 'organizations':
            return ['organization'];
          default:
            return [];
        }
      },
    }),
    /// this endpoint is not used. Left for safety
    removeCoverImage: build.mutation({
      query: (params) => ({
        url: `/amazon-s3?folder=${params.folder}&${params.table}=${params.id}`,
        method: 'DELETE',
        body: params.data,
      }),
    }),

    removeImage: build.mutation({
      query: (params) => ({
        url: `/amazon-s3/${params.id}?${params.table}=${params.id}`,
        method: 'DELETE',
        params,
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useUploadCoverImageMutation, useRemoveCoverImageMutation, useRemoveImageMutation } = imageApi;
