import MODAL_TYPES from 'constants/modals';
import { showModal } from 'store/slices/modal';
import { ENTITY_TYPES } from 'common';

import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import Dropdown from 'style-guide/DropdownV2';
import Menu, { MenuItem } from 'style-guide/Menu';
import Button from 'style-guide/Button';

const { LEARNING_COMMUNITY } = ENTITY_TYPES;

const courseSettingsPath = (item) => `/${LEARNING_COMMUNITY.key}/manage/edit-settings/${item.id}/${item.urlName}`;

const setModalTitleText = (modalTitle, item) => {
  switch (modalTitle) {
    case 'delete':
      return 'You want to delete the course?';
    case 'archive':
      return `You want to ${item.isArchive === true ? 'unarchive' : 'archive'} the course?`;
    default:
      null;
  }
};

const showSettingsModal = (dispatch, id) => {
  dispatch(
    showModal({
      type: MODAL_TYPES.VIEW_COURSE_SETTINGS,
      params: {
        modal: {
          title: (
            <div className='col-12'>
              <Title>Allow comment</Title>
            </div>
          ),
        },
        body: {
          id,
        },
      },
    })
  );
};

const showConfirmationModal = (dispatch, deleteCourse, updateArchive, updateDemo, item, title) => {
  dispatch(
    showModal({
      type: MODAL_TYPES.CONFIRMATION,
      params: {
        modal: {
          title: <Title>Are you sure</Title>,
        },
        body: {
          title: (
            <Text $variant={2} $dark={100}>
              {setModalTitleText(title, item)}
            </Text>
          ),
          onOk: () => {
            switch (title) {
              case 'delete':
                deleteCourse({ id: item.id });
                break;
              case 'archive':
                updateArchive({
                  courseId: item.id,
                  isArchive: !item.isArchive,
                  mode: 'course',
                });
                break;
              default:
                updateDemo({ id: item.id, admin: true, isDemo: !item.isDemo });
            }
          },
        },
      },
    })
  );
};

const Actions = ({ item, setCourseUserData, dispatch, deleteCourse, updateArchive, updateDemo }) => (
  <Dropdown
    trigger='click'
    placement='bottomLeft'
    className='role-dropdown'
    overlay={
      <Menu>
        <MenuItem key='edit-course' onClick={() => setCourseUserData(item, courseSettingsPath)}>
          Edit Course
        </MenuItem>
        <MenuItem
          key='settings'
          onClick={() => {
            showSettingsModal(dispatch, item.id);
          }}
        >
          Allow comment
        </MenuItem>
        <MenuItem
          className='archive'
          onClick={() => showConfirmationModal(dispatch, deleteCourse, updateArchive, updateDemo, item, 'archive')}
        >
          Archive Course
        </MenuItem>
        <MenuItem
          key='delete-course'
          onClick={() => showConfirmationModal(dispatch, deleteCourse, updateArchive, updateDemo, item, 'delete')}
        >
          Remove Course
        </MenuItem>
      </Menu>
    }
  >
    {' '}
    <Button variant='link'>...</Button>
  </Dropdown>
);

export default Actions;
