import PATHS from 'constants/paths';
import { Suspense, lazy } from 'react';

import PageSpin from 'style-guide/Spin/PageSpin';
import AppRouterLayout from '../AppRouterLayout';
import authenticatedUserRouter from './authenticated';
import entitiesHomepageRoutes from './entityPagesRoute/entitiesHomepageRoutes';
import notAuthenticatedRoute from './notAuthenticated';
import redirectRoutes from './redirectRoutes';
import staticPageRoutes from './staticPageRoutes';

const Home = lazy(() => import('views/Home'));
const ExploreLearningCommunities = lazy(() => import('views/NewExplore/LearningCommunities'));
const ExploreCollections = lazy(() => import('views/NewExplore/Collections'));
const ExploreOrganizations = lazy(() => import('views/NewExplore/Organizations'));
const ExploreFavorites = lazy(() => import('views/NewExplore/Favorites'));
const ExploreMembers = lazy(() => import('views/NewExplore/Members'));

const mainRoute = (isAuth) => [
  {
    path: '/',
    element: (
      <Suspense fallback={<PageSpin />}>
        <AppRouterLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },

      {
        path: PATHS.EXPLORE_LEARNING_COMMUNITIES,
        element: <ExploreLearningCommunities />,
      },
      {
        path: PATHS.EXPLORE_COLLECTIONS,
        element: <ExploreCollections />,
      },
      {
        path: PATHS.EXPLORE_ORGANIZATIONS,
        element: <ExploreOrganizations />,
      },
      {
        path: PATHS.EXPLORE_FAVORITES,
        element: <ExploreFavorites />,
      },
      {
        path: PATHS.EXPLORE_MEMBERS,
        element: <ExploreMembers />,
      },
      ...staticPageRoutes,
      ...entitiesHomepageRoutes,
      ...authenticatedUserRouter(isAuth),
      ...notAuthenticatedRoute(isAuth),
    ],
  },
  ...redirectRoutes,
];

export default mainRoute;
