import classNames from 'classnames';
import { SYSTEM_LIMITS } from 'common';
import { forwardRef, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { message } from 'style-guide/ToastMessages';

import { validateImageSizeOnUpload } from 'utils/imageHelpers';

import DragAndDropWrapper from './style/DragAndDropWrapper';

const DragAndDropImage = forwardRef(
  (
    { Body, className, defaultImage, setValue, position, positionName, name, validate, formValue, ...inputProps },
    ref
  ) => {
    const [image, setImage] = useState(null);

    const onDrop = useCallback(
      async (acceptedFiles, fileRejections) => {
        const formData = new FormData();
        const [file] = acceptedFiles;
        if (file && file?.type?.split('/')[0] !== 'image') {
          return message.error('Wrong file type');
        }
        if (!acceptedFiles[0]) {
          const rejectedFile = fileRejections[0];
          return message.error(rejectedFile.errors[0].message);
        }
        const validationData = validate ? await validateImageSizeOnUpload(file, name) : { isValid: true };
        if (!validationData.isValid) {
          return message.error(validationData?.message);
        }
        setValue(formValue, URL.createObjectURL(file));
        setImage(URL.createObjectURL(file));
        formData.append('file', file);
        setValue(name, formData);
        if (position) {
          setValue(position, { x: 0, y: 0 });
        }
      },
      [validate, formValue, name, position, setValue]
    );

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      minSize: 0,
      maxSize: SYSTEM_LIMITS.FILES.size.max,
      noDrag: !!defaultImage,
    });
    return (
      <DragAndDropWrapper
        className={classNames('drag-and-drop-image mt--8 row align-items-center', className)}
        {...getRootProps()}
      >
        <input {...getInputProps()} ref={ref} {...inputProps} />
        <Body
          image={image || defaultImage}
          setValue={setValue}
          position={position}
          sectionName={name}
          {...inputProps}
        />
      </DragAndDropWrapper>
    );
  }
);

export default DragAndDropImage;
