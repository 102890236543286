import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useUpdateArchiveMutation } from 'store/services/archive';
import { useDeleteDepartmentMutation, useGetAdminDepartmentsQuery } from 'store/services/superAdmin/AdminDepartments';
import { useLazyGetAdminDataQuery } from 'store/services/superAdmin/AdminUsers';
import { changeUserData } from 'store/slices/profile';

import { getDomainTracking, saveLocalStorage } from 'configs/localStorage';

import Pagination from 'style-guide/Pagination';
import Table from 'style-guide/Table';

import params from '../helper/helperFunction';
import { COURSES_CONST, getDepartmentsColumns } from './columns';

const SuperAdminDepartments = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const domainId = getDomainTracking().id;

  const [trigger] = useLazyGetAdminDataQuery();
  const [deleteDepartment] = useDeleteDepartmentMutation();
  const [updateArchive] = useUpdateArchiveMutation();
  const { data: departmentsList } = useGetAdminDepartmentsQuery(params({ page, domainId, searchParams }));

  const setCourseUserData = async (item, setPath) => {
    await trigger({ id: item?.id, table: COURSES_CONST.LIBRARIES }).then((data) => {
      saveLocalStorage({
        user: data?.data,
      });
      dispatch(changeUserData({ isAuth: true, userData: data?.data }));
      navigate(setPath(item));
    });
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <Table
          columns={getDepartmentsColumns({
            setCourseUserData,
            dispatch,
            deleteDepartment,
            updateArchive,
          })}
          data={departmentsList?.rows}
          rowKey={(row) => row.id}
        />
        <div className='mt--24'>
          <Pagination total={departmentsList?.count || 0} current={page} onChange={setPage} className='pagination' />
        </div>
      </div>
    </div>
  );
};

export default SuperAdminDepartments;
