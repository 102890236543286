export const awsStampPicture = {
  folder: 'stampImages',
  table: 'domain_tracking',
  key: 'stamp',
  column: 'stamp',
};

export const awsSignaturePicture = {
  folder: 'signatureImages',
  table: 'domain_tracking',
  key: 'signature',
  column: 'signature',
};
