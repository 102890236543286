import emptySplitApi from '..';

const reportsApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getQuestionSettings: build.query({
      query: ({ courseId, chapterId }) => ({
        url: `courses/${courseId}/sections/test/${chapterId}/settings`,
        method: 'GET',
      }),
      providesTags: ['questionSettings'],
    }),
    shuffleQuestions: build.mutation({
      query: (params) => ({
        url: `courses/${params.courseId}/sections/test/${params.chapterId}/settings`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['questionSettings'],
    }),
  }),
});

export default reportsApi;
export const { useShuffleQuestionsMutation, useGetQuestionSettingsQuery } = reportsApi;
