import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { upsertQueryData } from 'store/redux-toolkit-common';
import { useAddCompanyMutation, useGetDomainQuery } from 'store/services/superAdmin/AdminCourses';
import { useUploadCoverImageMutation } from 'store/services/uploadImage';
import Button from 'style-guide/Button';
import DividerWithText from 'style-guide/DividerWithText';
import DropdownV2 from 'style-guide/DropdownV2';
import Form from 'style-guide/Form';
import { Down } from 'style-guide/Icons';
import { Input } from 'style-guide/Input';
import { message } from 'style-guide/ToastMessages';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import { awsSignaturePicture, awsStampPicture } from 'views/SuperAdmin/helper/awsImageDetails';
import CompanySection from './CompanySection';
import DropdownLanguages from './DropdownLanguages';
import { basicInformation, certificateImages, contactInformation, servicesAndFees, socialLinks } from './formFields';
import schema from './schema';
import AddCompanyWrapper from './style/AddCompanyWrapper';

const AddCompanyV2 = () => {
  const { domainId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: domain } = useGetDomainQuery(
    { id: domainId },
    { refetchOnMountOrArgChange: true, skip: domainId === '0' }
  );

  const [createCompany, { isSuccess }] = useAddCompanyMutation();
  const [uploadCoverImage] = useUploadCoverImageMutation();

  const isAddCase = +domainId === 0;
  const titleText = isAddCase ? 'Domain settings' : domain?.brandName;
  const buttonText = isAddCase ? 'Add Company' : 'Save Changes';

  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const stampImage = watch('stamp');
  const signature = watch('signature');
  const languageValues = watch('languages') || {};

  const getCheckedItems = () =>
    Object.entries(languageValues)
      .filter(([, isChecked]) => isChecked === true)
      .map(([languageCode]) => languageCode);

  const uploadImage = async ({ imageData, awsImageDetails, id }) => {
    await uploadCoverImage({ data: imageData, ...awsImageDetails, id });
  };

  const onFormSubmit = async (data) => {
    const result = await createCompany({ ...data, languages: getCheckedItems().toString(), id: +domainId });
    const stampSectionData = data?.stampSection instanceof FormData && data?.stampSection?.get('file').name;
    const stampFileName = stampImage?.split('_', 2);
    const isStampImageSame = stampSectionData === stampFileName?.[1];

    if (result?.data && stampSectionData && !isStampImageSame) {
      await uploadImage({
        imageData: data?.stampSection,
        awsImageDetails: awsStampPicture,
        id: data?.id || result?.data?.id,
      });
      dispatch(upsertQueryData('getDomain', { id: domainId }, undefined));
    }

    const signatureSectionData = data?.signatureSection instanceof FormData && data?.signatureSection?.get('file').name;
    const signatureFileName = signature?.split('_', 2);
    const isSignatureImageSame = signatureSectionData === signatureFileName?.[1];

    if (result?.data && signatureSectionData && !isSignatureImageSame) {
      await uploadImage({
        imageData: data?.signatureSection,
        awsImageDetails: awsSignaturePicture,
        id: data?.id || result?.data?.id,
      });
      dispatch(upsertQueryData('getDomain', { id: domainId }, undefined));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Successfully updated');
      navigate('/admin/Companies');
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (domain?.id) {
      reset(domain);
    }
  }, [domain, reset]);

  return (
    <AddCompanyWrapper>
      <Title variant={4}>{titleText}</Title>
      <Text>Manage company settings and preferences</Text>
      <DividerWithText className='divider' />
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <CompanySection register={register} errors={errors} title='Basic Information' section={basicInformation} />
        <DividerWithText className='divider' />
        <CompanySection
          register={register}
          errors={errors}
          control={control}
          title='Contact Information'
          section={contactInformation}
        />
        <DividerWithText className='divider' />
        <CompanySection register={register} errors={errors} title='Services and Fees' section={servicesAndFees} />
        <DividerWithText className='divider' />
        <CompanySection register={register} errors={errors} title='Social Links' section={socialLinks} />
        <DividerWithText className='divider' />
        <div className='row'>
          <Text className='col-3'>Language Settings</Text>
          <div className='col-9'>
            <div className='row'>
              <div className='col-5 ml--40'>
                <DropdownV2
                  overlay={
                    <DropdownLanguages
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      languages={domain?.languages}
                    />
                  }
                >
                  <Input label='Language settings' Suffix={Down} readOnly placeholder='Select Languages' />
                </DropdownV2>
              </div>
            </div>
          </div>
        </div>
        <DividerWithText className='divider' />
        <div className='row'>
          <Text className='col-3'>Certificates</Text>
          <div className='col-9'>
            <div className='row'>
              <div className='col-4 ml--40'>
                {certificateImages({ register, setValue, stampImage, signature }).map((field) => field.component)}
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex mt--56 justify-content-end'>
          <Button variant='secondary' className='mr--12'>
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            {buttonText}
          </Button>
        </div>
      </Form>
    </AddCompanyWrapper>
  );
};

export default AddCompanyV2;
