import MODAL_TYPES from 'constants/modals';
import { showModal } from 'store/slices/modal';
import { Link } from 'react-router-dom';
import Text from 'style-guide/Typography/Text';
import SimplifiedEditor from 'Components/RichEditor/SimplifiedEditor';

const showStaticPagesModal = (dispatch, item) => {
  dispatch(
    showModal({
      type: MODAL_TYPES.STATIC_PAGES_MODAL,
      params: {
        body: {
          item,
        },
      },
    })
  );
};

export const getStaticPagesColumns = ({ dispatch }) => [
  {
    title: 'URL',
    dataIndex: 'key',
    key: 'key',
    width: 250,
    render: (_index, item) => <Link onClick={() => showStaticPagesModal(dispatch, item)}> {item.key}</Link>,
  },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
    className: 'content-cell',
    render: (_index, item) => (
      <Text $variant={2}>
        <SimplifiedEditor content={item?.content} editable={false} />
      </Text>
    ),
  },
];

export const COURSES_CONST = {
  NAME: 'name',
  STATIC_PAGES: 'key',
};
