import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUpdateArchiveMutation } from 'store/services/archive';
import { changeUserData } from 'store/slices/profile';

import { getDomainTracking, saveLocalStorage } from 'configs/localStorage';

import Pagination from 'style-guide/Pagination';
import Table from 'style-guide/Table';

import {
  useDeleteCourseMutation,
  useGetSuperAdminCoursesQuery,
  useUpdateDemoMutation,
} from 'store/services/superAdmin/AdminCourses';
import { useLazyGetAdminDataQuery } from 'store/services/superAdmin/AdminUsers';
import params from '../helper/helperFunction';
import { COURSES_CONST, getColumns } from './columns';

const SuperAdminCourses = () => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const domainId = getDomainTracking().id;

  const [trigger] = useLazyGetAdminDataQuery();
  const [deleteCourse] = useDeleteCourseMutation();
  const [updateDemo] = useUpdateDemoMutation();
  const [updateArchive] = useUpdateArchiveMutation();
  const { data: courses } = useGetSuperAdminCoursesQuery(params({ page, domainId, searchParams }));

  const setCourseUserData = async (item, setPath) => {
    await trigger({ id: item.id, table: COURSES_CONST.COURSES }).then((data) => {
      saveLocalStorage({
        user: data?.data,
      });
      dispatch(changeUserData({ isAuth: true, userData: data?.data }));
      navigate(setPath(item));
    });
  };

  return (
    <>
      <Table
        columns={getColumns({
          setCourseUserData,
          dispatch,
          deleteCourse,
          updateArchive,
          updateDemo,
        })}
        rowKey={(row) => row.id}
        data={courses?.rows}
      />
      <div className='mt--24'>
        <Pagination total={courses?.count || 0} current={page} onChange={setPage} className='pagination' />
      </div>
    </>
  );
};
export default SuperAdminCourses;
