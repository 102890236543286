import styled from 'styled-components';

const DropdownLanguagesWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white[100]};
  box-shadow: 0px 0px 12px ${({ theme }) => theme.colors.boxShadow[25]};
  overflow-y: auto;
  max-height: 500px;
`;

export default DropdownLanguagesWrapper;
