import DragAndDropImage from 'Components/EntityCreationFlow/EntityFormSections/BrandingForm/DrangAndDropImage';
import MainImageContent from 'Components/EntityCreationFlow/EntityFormSections/BrandingForm/DrangAndDropImage/MainImageContent';
import { toAwsSignatureImage, toAwsStampImage } from 'utils/images';

export const basicInformation = [
  {
    name: 'key',
    label: 'Key',
    required: true,
  },

  {
    name: 'mainSite',
    label: 'Main Site URL',
  },
  {
    name: 'brandName',
    label: 'Brand Name',
    required: true,
  },
  {
    name: 'domainName',
    label: 'Domain Name',
    required: true,
  },
  {
    name: 'companyDescription',
    label: 'Company Description',
  },
  {
    name: 'keywords',
    label: 'Keywords',
  },
];

export const contactInformation = [
  {
    name: 'email',
    label: 'Email',
    required: true,
  },
  {
    name: 'phone',
    label: 'Phone',
  },
  {
    name: 'supportEmail',
    label: 'Support email',
    required: true,
  },
  {
    name: 'address',
    label: 'Address',
    required: true,
  },
  {
    name: 'companyType',
    label: 'Company Type',
    required: true,
  },
  {
    name: 'fax',
    label: 'Fax',
  },
];

export const servicesAndFees = [
  {
    name: 'serviceFee',
    label: 'Service Fee',
    required: true,
  },
];

export const socialLinks = [
  {
    name: 'linkedin',
    label: 'Linkedin URL',
  },
  {
    name: 'facebook',
    label: 'Facebook URL',
  },
  {
    name: 'twitter',
    label: 'Twitter URL',
  },
  {
    name: 'youtube',
    label: 'Youtube URL',
  },
  {
    name: 'soundCloud',
    label: 'SoundCloud URL',
  },
];

export const languageSettings = [
  {
    name: 'languageSettings',
    label: 'Language Settings',
  },
];

export const certificateImages = ({ register, stampImage, setValue, signature }) => [
  {
    name: 'stamp',
    label: 'Certificate Stamp',
    component: (
      <DragAndDropImage
        className='main-image'
        defaultImage={stampImage && toAwsStampImage(stampImage)}
        setValue={setValue}
        {...register('stampSection')}
        Body={MainImageContent}
      />
    ),
  },
  {
    name: 'signature',
    label: 'Certificate Signature',
    component: (
      <DragAndDropImage
        className='main-image'
        defaultImage={signature && toAwsSignatureImage(signature)}
        setValue={setValue}
        {...register('signatureSection')}
        Body={MainImageContent}
        widthPlaceholder={104}
        heightPlaceholder={40}
      />
    ),
  },
];
