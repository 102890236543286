import styled from 'styled-components';
import itemRender from './itemRender';
import PaginationComponent from './pagination';

const Pagination = styled(PaginationComponent)`
  &.rc-pagination {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    padding: 0;
    margin: 0;
    margin-right: 16px;
    li {
      list-style: none;
      cursor: pointer;
      justify-content: center;
      font-size: 14px;
      &:hover {
        background-color: transparent;
      }
      &.rc-pagination-item {
        text-align: center;
        height: 18px;
        padding: 0 4px;
        margin: 0 2px;
        color: ${({ theme }) => theme.colors.dark[60]};
      }
      &.rc-pagination-item-active {
        border-bottom: 1px solid ${({ theme }) => theme.colors.primary[600]};
        color: ${({ theme }) => theme.colors.dark[100]};
      }
      &.rc-pagination-prev,
      &.rc-pagination-next {
        color: ${({ theme }) => theme.colors.primary[600]};
        margin: 0 2.5px;
        path {
          fill: ${({ theme }) => theme.colors.primary[600]};
        }
        &.rc-pagination-disabled {
          color: ${({ theme }) => theme.colors.dark[25]};
          path {
            fill: ${({ theme }) => theme.colors.dark[25]};
          }
        }
        .arrow {
          display: flex;
          align-items: center;
        }
        svg {
          &.left {
            padding-right: 10px;
          }
          &.right {
            padding-left: 10px;
          }
        }
      }

      &.rc-pagination-jump-prev,
      &.rc-pagination-jump-next {
        outline: 0;
        transition: all 0.3s;
        height: 18px;
        width: 17px;
        margin: 0 2.5px;
        position: relative;
        button {
          background: transparent;
          color: ${({ theme }) => theme.colors.dark[60]};
          border: none;
          cursor: pointer;
          padding: 0;
          bottom: 0;
          &:after {
            font-size: 8px;
            position: relative;
            bottom: -3px;
            left: 3px;
            display: flex;
            justify-content: center;
            content: '•••';
          }
        }
      }
    }
  }
`;

Pagination.defaultProps = {
  itemRender,
  showTitle: false,
};

export default Pagination;
