export const LANGUAGE_ITEMS = [
  { value: 'Afrikaans (South Africa)', languageCode: 'af' },
  { value: 'Arabic', languageCode: 'ar' },
  { value: 'Basque (Spain)', languageCode: 'eu' },
  { value: 'Bengali (India)', languageCode: 'bn' },
  { value: 'Bulgarian (Bulgaria)', languageCode: 'bg' },
  { value: 'Catalan (Spain)', languageCode: 'ca' },
  { value: 'Chinese (Hong Kong)', languageCode: 'zh' },
  { value: 'Czech (Czech Republic)', languageCode: 'cs' },
  { value: 'Danish (Denmark)', languageCode: 'da' },
  { value: 'Dutch', languageCode: 'nl' },
  { value: 'Filipino (Philippines)', languageCode: 'fil' },
  { value: 'Finnish (Finland)', languageCode: 'fi' },
  { value: 'French (France)', languageCode: 'fr-FR' },
  { value: 'Galician (Spain)', languageCode: 'gl' },
  { value: 'German (Germany)', languageCode: 'de' },
  { value: 'Greek (Greece)', languageCode: 'el' },
  { value: 'Gujarati (India)', languageCode: 'gu' },
  { value: 'Hebrew (Israel)', languageCode: 'iw' },
  { value: 'Hindi (India)', languageCode: 'hi' },
  { value: 'Icelandic (Iceland)', languageCode: 'is' },
  { value: 'Indonesian (Indonesia)', languageCode: 'id' },
  { value: 'Italian (Italy)', languageCode: 'it' },
  { value: 'Japanese (Japan)', languageCode: 'hu' },
  { value: 'Korean', languageCode: 'ko' },
  { value: 'Latvian (Latvia)', languageCode: 'lv' },
  { value: 'Lithuanian (Lithuania)', languageCode: 'lt' },
  { value: 'Malay (Malaysia)', languageCode: 'ms' },
  { value: 'Malayalam (India)', languageCode: 'ml' },
  { value: 'Marathi (India)', languageCode: 'mr' },
  { value: 'Norwegian (Norway)', languageCode: 'no' },
  { value: 'Polish (Poland)', languageCode: 'pl' },
  { value: 'Portuguese (Portugal)', languageCode: 'pt-PT' },
  { value: 'Punjabi (India)', languageCode: 'pa' },
  { value: 'Romanian (Romania)', languageCode: 'ro' },
  { value: 'Russian (Russia)', languageCode: 'ru' },
  { value: 'Serbian (Cyrillic)', languageCode: 'sr' },
  { value: 'Slovak (Slovakia)', languageCode: 'sk' },
  { value: 'Spanish (Spain)', languageCode: 'es' },
  { value: 'Swedish (Sweden)', languageCode: 'sv' },
  { value: 'Tamil (India)', languageCode: 'ta' },
  { value: 'Telugu (India)', languageCode: 'te' },
  { value: 'Thai (Thailand)', languageCode: 'th' },
  { value: 'Turkish (Turkey)', languageCode: 'tr' },
  { value: 'Ukrainian (Ukraine)', languageCode: 'uk' },
  { value: 'Vietnamese (Vietnam)', languageCode: 'vi' },
];
