import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const DragAndDropWrapper = styled.div`
  &.drag-and-drop-image {
    text-align: center;

    .tertiary-text {
      color: ${({ theme }) => theme.colors.tertiary[400]};
    }

    &.main-image {
      display: inline-block;
      .images-box {
        height: 212px;
        width: 212px;
      }
    }
    &.background-image {
      .images-box {
        height: 171px;
        .draggable {
          cursor: n-resize;
        }
        img {
          -webkit-user-drag: none;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }
      }
    }
    .images-box {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11.5' stroke='%2333333333' stroke-width='2' stroke-dasharray='11.5' stroke-dashoffset='6' stroke-linecap='butt'/%3e%3c/svg%3e");
      .draggable-box {
        overflow: hidden;
        height: 100%;
        width: 100%;
        border-radius: 12px;
      }
      &.has-image {
        background-image: none;
        img {
          object-fit: cover;
          border-radius: 12px;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      border: none;
    }

    @media only screen and (min-width: ${SCREEN_SIZES.MD}px) and (max-width: ${SCREEN_SIZES.LG}px) {
      &.main-image {
        .images-box {
          height: 135px;
          width: 135px;
        }
      }
      &.background-image {
        .images-box {
          height: 117px;
        }
      }
    }

    @media only screen and (min-width: ${SCREEN_SIZES.XS}px) and (max-width: ${SCREEN_SIZES.MD}px) {
      &.main-image {
        .images-box {
          height: 135px;
          width: 135px;
        }
      }
      &.background-image {
        .images-box {
          height: 88px;
        }
      }
    }
  }
`;

export default DragAndDropWrapper;
