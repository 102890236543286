const Plus = (props) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.00293 7.20703H14.0029V9.20703H9.00293V14.207H7.00293V9.20703H2.00293V7.20703H7.00293V2.20703H9.00293V7.20703Z'
      fill='currentColor'
    />
  </svg>
);

export default Plus;
