import PATHS from 'constants/paths';
import { lazy } from 'react';
import SuperAdminTable from 'views/SuperAdmin/SuperAdminTable';
import { IsSuperAdminRouter } from './IsSuperAdminRouter';

const SuperAdmin = lazy(() => import('views/SuperAdmin'));
const AddCompanyV2 = lazy(() => import('views/SuperAdmin/Companies/AddCompanyV2'));

const superAdminPaths = (user) =>
  [
    {
      element: <SuperAdmin />,
      path: PATHS.ADMIN,
      children: [
        {
          path: PATHS.ADMIN_PAGE_CATEGORY,
          element: <SuperAdminTable />,
          children: [
            {
              element: <AddCompanyV2 />,
              path: PATHS.ADD_COMPANY,
            },
          ],
        },
      ],
    },
  ].map((e) => ({
    ...e,
    element: <IsSuperAdminRouter user={user}>{e.element}</IsSuperAdminRouter>,
  }));

export default superAdminPaths;
