const Settings = (props) => (
  <svg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.9711 19.8117L20.1267 17.6561L19.3675 14.8981L19.6983 14.1082L22.1943 12.6997V9.65145L19.7054 8.23714L19.3802 7.44862L20.1489 4.68914L17.9918 2.53516L15.2335 3.29412L14.4453 2.96366L13.0368 0.467407H9.98849L8.57417 2.95634L7.78583 3.28147L5.02679 2.5118L2.87305 4.66555L3.63214 7.42431L3.30167 8.21246L0.80542 9.62102V12.6684L3.29211 14.0859L3.61759 14.8751L2.849 17.6341L5.00356 19.7887L7.76233 19.0296L8.55057 19.3601L9.95915 21.8553H13.0067L14.4231 19.3686L15.2121 19.0431L17.9711 19.8117ZM18.1622 12.7423L17.3146 14.7666L17.9517 17.0813L17.3991 17.6339L15.0893 16.9905L13.0641 17.8257L11.8764 19.9109H11.0944L9.91651 17.8244L7.89443 16.9765L5.57851 17.6138L5.02684 17.0621L5.67028 14.7523L4.8351 12.7273L2.74986 11.5386V10.7565L4.83732 9.57859L5.68519 7.55642L5.04796 5.2405L5.59849 4.68997L7.90824 5.3343L9.93415 4.49878L11.12 2.41185H11.9013L13.0792 4.49931L15.1014 5.34718L17.4177 4.70982L17.9708 5.26208L17.3275 7.57139L18.1629 9.59711L20.2499 10.783V11.5643L18.1622 12.7423ZM11.4999 15.0507C9.35209 15.0507 7.61098 13.3096 7.61098 11.1619C7.61098 9.01408 9.35209 7.27296 11.4999 7.27296C13.6476 7.27296 15.3888 9.01408 15.3888 11.1619C15.3888 13.3096 13.6476 15.0507 11.4999 15.0507ZM13.4443 11.1619C13.4443 12.2357 12.5738 13.1063 11.4999 13.1063C10.426 13.1063 9.55542 12.2357 9.55542 11.1619C9.55542 10.088 10.426 9.21741 11.4999 9.21741C12.5738 9.21741 13.4443 10.088 13.4443 11.1619Z'
      fill='currentColor'
    />
  </svg>
);

export default Settings;
