import { getDomainTracking } from 'configs/localStorage';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetDomainsQuery } from 'store/services/superAdmin/AdminCourses';
import Button from 'style-guide/Button';
import Pagination from 'style-guide/Pagination';
import Table from 'style-guide/Table';
import getCompaniesColumns from './columns';

const Companies = () => {
  const [page, setPage] = useState(1);
  const { id: domainId } = getDomainTracking();

  const { data } = useGetDomainsQuery({ page, domainId }, { refetchOnMountOrArgChange: true });
  const navigate = useNavigate();

  return (
    <>
      <div className='d-flex justify-content-end mb--24'>
        <Button variant='primary' onClick={() => navigate('/admin/companies/0')}>
          Add Company
        </Button>
      </div>
      <div className=''>
        <Table columns={getCompaniesColumns()} data={data?.rows} rowKey={(row) => row.id} />
        <div className='mt--24'>
          <Pagination total={data?.count || 0} current={page} onChange={setPage} className='pagination' />
        </div>
      </div>
    </>
  );
};

export default Companies;
