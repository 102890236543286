import styled from 'styled-components';
import RcDropdown from 'rc-dropdown';
import DropdownList from './DropdownList';
/* tmp fix */
const DropdownListWrapper = styled.div`
  .rc-menu {
    box-shadow: 0px 0px 12px ${({ theme }) => theme.colors.boxShadow[25]} !important;
    border-radius: 4px;
    li {
      padding: 6px 8px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      background-color: ${({ theme }) => theme.colors.white[100]};
      cursor: pointer;
      font-size: 12px;
      color: ${({ theme }) => theme.colors.dark[80]};
      &:hover {
        background-color: ${({ theme }) => theme.colors.secondary[50]};
      }
      &:nth-child(1) {
        box-shadow: none;
      }
      &:first-of-type {
        border-radius: 4px 4px 0 0;
      }
      &:last-child {
        border-radius: 0 0 4px 4px;
      }
    }
  }
`;

const Dropdown = ({
  className,
  options,
  overlay,
  onClickItem,
  space = 0,
  trigger = 'click',
  children,
  menuProps = {},
  ...props
}) => (
  <DropdownListWrapper>
    <RcDropdown
      trigger={trigger}
      {...props}
      onOverlayClick={(e) => e.stopPropagation()}
      getPopupContainer={(content) => content.parentNode}
      overlay={
        overlay || (
          <DropdownList
            className={className}
            options={options}
            onClickItem={onClickItem}
            style={{ marginTop: `${space}px` }}
            selectable={false}
            {...menuProps}
          />
        )
      }
    >
      <span className='dropdown-children'>{children}</span>
    </RcDropdown>
  </DropdownListWrapper>
);

export default Dropdown;
