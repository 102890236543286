import Title from 'style-guide/Typography/Title';
import Text from 'style-guide/Typography/Text';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'style-guide/Icons';

const getCompaniesColumns = () => [
  {
    title: <Title variant={5}>Name</Title>,
    dataIndex: 'brandName',
    key: 'name',
    render: (_index, item) => (
      <>
        <Text $variant={3} paragraph>
          <Link to={`/admin/companies/${item.id}`}> {item.brandName} </Link>
        </Text>
        <Text $variant={2} paragraph fontStyle='italic'>
          <Link to={item.domainName} target='_blank' rel='noopener noreferrer'>
            {item.domainName} <ExternalLink width={12} />
          </Link>
        </Text>
      </>
    ),
  },
  {
    title: <Title variant={5}>Details</Title>,
    dataIndex: 'email',
    key: 'email',
    render: (_index, item) => (
      <>
        <Text $variant={3} paragraph>
          Email: {item.email}
        </Text>
        <Text $variant={3} paragraph>
          Support Email: {item.supportEmail}
        </Text>
        <Text $variant={3} paragraph>
          Phone: {item.phone}
        </Text>
      </>
    ),
  },
  {
    title: (
      <Title variant={5} className='title'>
        Service Fee
      </Title>
    ),
    dataIndex: 'serviceFee',
    key: 'serviceFee',
    render: (_index, item) => (
      <Text $variant={3} paragraph>
        ${item.serviceFee}
      </Text>
    ),
  },
];

export default getCompaniesColumns;
