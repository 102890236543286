import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Input } from 'style-guide/Input';
import Text from 'style-guide/Typography/Text';

const CompanySection = ({ register, errors, title, section, control }) => (
  <div className='row'>
    <Text className='col-3'>{title}</Text>
    <div className='col-9'>
      <div className='row'>
        {section.map((field) => (
          <div key={field.name} className='col-5 ml--40'>
            {field.name === 'phone' ? (
              <Controller
                control={control}
                name='phone'
                render={({ field: { onChange, value } }) => (
                  <InputMask mask='1(999)999-9999' value={value} onChange={onChange}>
                    {(inputProps) => <Input error={errors.phone} label='Phone' {...inputProps} />}
                  </InputMask>
                )}
              />
            ) : (
              <Input {...field} {...register(field.name)} error={errors[field.name]} />
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default CompanySection;
