import Dropdown from 'style-guide/DropdownV2';
import Button from 'style-guide/Button';
import Menu, { MenuItem } from 'style-guide/Menu';
import MODAL_TYPES from 'constants/modals';
import { showModal } from 'store/slices/modal';
import Text from 'style-guide/Typography/Text';

import { generateEntityPagePath } from 'Components/App/Helpers/entityPagesRoute/pathGenerateHelper';
import { formStepsId } from 'Components/EntityFormSections/Stepper/helper';
import { ENTITY_TYPES } from 'common';

const { DEPARTMENT } = ENTITY_TYPES;

const colSettingsPath = (lib) => generateEntityPagePath(DEPARTMENT.key, lib.id, lib.urlName, formStepsId.naming);

const setModalTitleText = (modalTitle, lib) => {
  switch (modalTitle) {
    case 'delete':
      return (
        <Text $variant={2} $dark={100}>
          You want to delete the {DEPARTMENT.lowercase}?
        </Text>
      );
    case 'archive':
      return (
        <Text $variant={2} $dark={100}>
          {`You want to ${lib.isArchive === true ? 'unarchive' : 'archive'} the ${DEPARTMENT.lowercase}?`}
        </Text>
      );
    default:
      return null;
  }
};

const showConfirmationModal = (dispatch, deleteDepartment, updateArchive, item, title) => {
  dispatch(
    showModal({
      type: MODAL_TYPES.CONFIRMATION,
      params: {
        body: {
          title: setModalTitleText(title, item),
          onOk: () => {
            switch (title) {
              case 'delete':
                deleteDepartment({ id: item.id });
                break;
              case 'archive':
                updateArchive({
                  courseId: item.id,
                  isArchive: !item.isArchive,
                  mode: 'library',
                });
                break;
              default:
                null;
            }
          },
        },
      },
    })
  );
};

const Actions = ({ item, setCourseUserData, dispatch, deleteDepartment, updateArchive }) => (
  <div className='d-flex align-items-center'>
    <Dropdown
      trigger='click'
      placement='bottomLeft'
      className='role-dropdown'
      overlay={
        <Menu>
          <MenuItem key='edit' onClick={() => setCourseUserData(item, colSettingsPath)}>
            Edit Collection
          </MenuItem>
          <MenuItem
            key='archive'
            onClick={() => showConfirmationModal(dispatch, deleteDepartment, updateArchive, item, 'archive')}
          >
            {item.isArchive === true ? 'Un-Archive' : 'Archive'}
          </MenuItem>
          <MenuItem
            key='remove'
            onClick={() => showConfirmationModal(dispatch, deleteDepartment, updateArchive, item, 'delete')}
          >
            Remove Collection
          </MenuItem>
        </Menu>
      }
    >
      <Button variant='link'>...</Button>
    </Dropdown>
  </div>
);

export default Actions;
