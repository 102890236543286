import { useEffect, useState } from 'react';
import { useLazyGetEmailTrackingQuery } from 'store/services/emailTracking';
import useGetDomainTracking from 'store/services/helperHooks/useGetDomainTracking';
import Pagination from 'style-guide/Pagination';
import Table from 'style-guide/Table';
import { getEmailTrackingColumns } from './column';

const SuperAdminEmailTracking = () => {
  const [page, setPage] = useState(1);
  const { data: domainData } = useGetDomainTracking();
  const [getEmailTracking, { data: emailsTracking }] = useLazyGetEmailTrackingQuery();

  useEffect(() => {
    getEmailTracking({ page, domainId: domainData?.id });
  }, [getEmailTracking, domainData, page]);

  return (
    <div className='row'>
      <div className='col-12'>
        <Table columns={getEmailTrackingColumns()} data={emailsTracking?.rows} rowKey={(row) => row.id} />
        <div className='mt--24'>
          <Pagination total={emailsTracking?.count} current={page} onChange={setPage} className='pagination' />
        </div>
      </div>
    </div>
  );
};

export default SuperAdminEmailTracking;
