import { useState } from 'react';
import { useUpdateStaticPageMutation } from 'store/services/superAdmin/AdminStaticPages';

import Button from 'style-guide/Button';

import SimplifiedEditor from 'Components/RichEditor/SimplifiedEditor';

const StaticPagesModal = ({ hideModal, params: { item } }) => {
  const [updateStaticPage] = useUpdateStaticPageMutation();
  const [content, setContent] = useState(item.content);

  return (
    <div className='row transform-active '>
      <div className='col-12 justify-content-start'>
        <SimplifiedEditor onChange={setContent} content={content} className='description-editor ph--12 pb--12 mb--8' />
      </div>
      <div className='col-12 d-flex justify-content-end p--20'>
        <Button
          className='mr--4'
          variant='secondary'
          onClick={() => {
            hideModal();
          }}
        >
          Cancel
        </Button>
        <Button
          variant='primary'
          onClick={async () => {
            await updateStaticPage({ content, id: item.id });
            await hideModal();
          }}
        >
          ok
        </Button>
      </div>
    </div>
  );
};

export default StaticPagesModal;
