import { SYSTEM_LIMITS } from 'common';
import { number, object, string } from 'yup';

const descriptionMaxLength = SYSTEM_LIMITS.DEFAULTS.companyDescription.max;
const keywordsMaxLength = SYSTEM_LIMITS.DEFAULTS.keywords.max;

const schema = object({
  brandName: string().required('Brand Name is a required field'),
  key: string().required('Key is a required field'),
  domainName: string().url('The input is not a valid Url').required('Domain Name is a required field'),
  serviceFee: number()
    .typeError('The input is not valid')
    .integer()
    .min(1)
    .max(100)
    .required('Service Fee is a required field'),
  email: string().email('The input is not valid E-mail').required('Email is a required field'),
  phone: string().test(
    'is-valid-phone',
    'Phone number must be in the format 1(XXX)XXX-XXXX',
    (value) => !value || /^1\(\d{3}\)\d{3}-\d{4}$/.test(value)
  ),
  mainSite: string().url('The input is not a valid Url').nullable(),
  linkedin: string().url('The input is not a valid Url').nullable(),
  facebook: string().url('The input is not a valid Url').nullable(),
  twitter: string().url('The input is not a valid Url').nullable(),
  youtube: string().url('The input is not a valid Url').nullable(),
  soundCloud: string().url('The input is not a valid Url').nullable(),
  supportEmail: string().email('The input is not valid E-mail').required('Support Email is a required field'),
  address: string().required('Address is a required field'),
  companyType: string().required('Company Type is a required field'),
  fax: string().nullable(),
  companyDescription: string().max(descriptionMaxLength, `Must be maximum ${descriptionMaxLength} symbols`).nullable(),
  keywords: string().max(keywordsMaxLength, `Must be maximum ${keywordsMaxLength} symbols`).nullable(),
}).required();

export default schema;
