import { lazy } from 'react';

import PATHS from 'constants/paths';
import { IsAuthenticatedRoute } from './IsAuthenticatedRoute';
import entitiesCreatePageRoutes from './entityPagesRoute/entitiesCreatePageRoutes';

import entitiesPageRoutes from './entityPagesRoute/entitiesPageRoutes';

const Dashboard = lazy(() => import('views/Dashboard'));
const LearningCommunitiesOrganizerDashboard = lazy(() => import('views/Organizer/MyLearningCommunities'));
const CollectionsMemberDashboard = lazy(() => import('views/Member/MyCollections'));
const OrganizationsMemberDashboard = lazy(() => import('views/Member/MyOrganizations'));
const LearningCommunitiesMemberDashboard = lazy(() => import('views/Member/MyLearningCommunities'));
const CollectionsOrganizerDashboard = lazy(() => import('views/Organizer/MyCollections'));
const OrganizationsOrganizerDashboard = lazy(() => import('views/Organizer/MyOrganizations'));
const LearningCommunityInvitations = lazy(() => import('views/Member/MyInvitations'));

const Profile = lazy(() => import('views/Profile'));
const Notifications = lazy(() => import('views/Notifications'));

const Review = lazy(() => import('views/Dashboard/Submissions/Review'));

const NotificationsSettings = lazy(() => import('views/Notifications/NotificationSettings'));

const router = [
  {
    element: <Dashboard />,
    path: PATHS.DASHBOARD,
  },
  {
    element: <LearningCommunitiesOrganizerDashboard />,
    path: PATHS.LEARNING_COMMUNITIES_DASHBOARD,
  },
  {
    element: <CollectionsOrganizerDashboard />,
    path: PATHS.COLLECTIONS_DASHBOARD,
  },
  {
    element: <OrganizationsOrganizerDashboard />,
    path: PATHS.ORGANIZATIONS_DASHBOARD,
  },
  {
    element: <LearningCommunitiesMemberDashboard />,
    path: PATHS.LEARNING_COMMUNITIES_MEMBER_DASHBOARD,
  },
  {
    element: <CollectionsMemberDashboard />,
    path: PATHS.COLLECTIONS_MEMBER_DASHBOARD,
  },
  {
    element: <OrganizationsMemberDashboard />,
    path: PATHS.ORGANIZATIONS_MEMBER_DASHBOARD,
  },
  {
    element: <LearningCommunityInvitations />,
    path: PATHS.LEARNING_COMMUNITIES_INVITATIONS_DASHBOARD,
  },
  {
    element: <Profile />,
    path: PATHS.PROFILE,
  },
  {
    element: <Notifications />,
    path: PATHS.NOTIFICATIONS,
  },
  { element: <NotificationsSettings />, path: PATHS.NOTIFICATIONS_SETTINGS_PAGE },
  {
    element: <Review />,
    path: PATHS.SUBMISSION_REVIEW,
  },

  ...entitiesCreatePageRoutes,
  ...entitiesPageRoutes,
];

const authenticatedUserRouter = (isAuth) =>
  router.map((e) => ({
    ...e,
    element: <IsAuthenticatedRoute isAuth={isAuth}>{e.element}</IsAuthenticatedRoute>,
  }));

export default authenticatedUserRouter;
