import MODAL_TYPES from 'constants/modals';
import { showModal } from 'store/slices/modal';
import { message } from 'style-guide/ToastMessages';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

export const unpublishEntityModal = (unpublish, id, isArchive, mode, dispatch, isPaid) => {
  dispatch(
    showModal({
      type: MODAL_TYPES.CONFIRMATION,
      params: {
        modal: {
          title: 'Confirmation',
        },
        body: {
          title: (
            <div className='d-flex flex-column'>
              <Title $variant={2} className='mb--12'>
                {isPaid && isArchive && 'This entity is a paid Learning Community'}
              </Title>
              <Text $variant={2}>Are you sure you want to {isArchive ? 'unpublish' : 'publish'} this LC?</Text>
            </div>
          ),
          onOk: () => {
            unpublish({ courseId: id, isArchive, mode }).then(() => {
              message.success('Successfully deleted');
            });
          },
        },
      },
    })
  );

  return null;
};
