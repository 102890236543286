import { ENTITY_TYPES } from 'common';
import { useParams } from 'react-router-dom';
import Companies from './Companies';
import AddCompanyV2 from './Companies/AddCompanyV2';
import SuperAdminCourses from './Courses';
import SuperAdminDepartments from './Departments';
import SuperAdminEmailTracking from './EmailTracking/SuperAdminEmailTracking';
import SuperAdminOrganizations from './Organizations';
import SuperAdminStaticPages from './StaticPages';
import AdminUsers from './Users';

const { ORGANIZATION, DEPARTMENT } = ENTITY_TYPES;

const SuperAdminTable = () => {
  const { category, domainId } = useParams();

  switch (category.toLowerCase()) {
    case 'users':
      return <AdminUsers />;
    case DEPARTMENT.key:
      return <SuperAdminDepartments />;
    case 'companies':
      return domainId ? <AddCompanyV2 /> : <Companies />;
    case ORGANIZATION.key:
      return <SuperAdminOrganizations />;
    case 'static-pages':
      return <SuperAdminStaticPages />;
    case 'notification-center':
      return <SuperAdminEmailTracking />;
    default:
      return <SuperAdminCourses />;
  }
};

export default SuperAdminTable;
