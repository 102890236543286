import styled from 'styled-components';

const AddCompanyWrapper = styled.div`
  .divider {
    &::before {
      border-bottom: 1px solid ${({ theme }) => theme.colors.boxShadow[15]};
    }
    &::after {
      border-bottom: 1px solid ${({ theme }) => theme.colors.boxShadow[15]};
    }
  }
`;

export default AddCompanyWrapper;
