import { USER_ACCESS_TYPES } from 'common';
import { useUpdateUserAccessMutation } from 'store/services/superAdmin/AdminUsers';
import Button from 'style-guide/Button';
import { ArrowBottom, ArrowTop } from 'style-guide/Icons';
import Tooltip from 'style-guide/Tooltip';
import SearchDropdown from '../SearchDropdown';
import DropDownFilter from '../SearchDropdown/DropdownFilter';
import Actions from './Actions';

const { getTypeFromId } = USER_ACCESS_TYPES;

const getColumns = ({ verifyUser, page, isSuperAdmin }) => {
  const [updateUserAccess] = useUpdateUserAccessMutation();

  return [
    {
      title: <SearchDropdown title='Name' name='firstName' />,
      dataIndex: 'firstName',
      key: 'firstName',
      render: (_index, item) => (
        <div className='d-flex '>
          {item.firstName}
          {item.lastName}
        </div>
      ),
    },
    {
      title: <SearchDropdown title='Last Name' name='lastName' />,
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: <SearchDropdown title='Username' name='userName' />,
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: <SearchDropdown title='Email' name='email' />,
      dataIndex: 'email',
      key: 'email',
      render: (_index, item) => (
        <div>
          {item.email}
          {!item.isVerified && (
            <Button
              className='mt--12'
              variant='secondary'
              onClick={() => verifyUser({ body: { id: item.id }, params: { page } })}
            >
              Verify
            </Button>
          )}
        </div>
      ),
    },
    ...(isSuperAdmin
      ? [
          {
            title: 'Role',
            dataIndex: 'brandName',
            key: 'brandName',
            render: (_index, item) => (
              <>
                {item.role} @ {item.brandName}
              </>
            ),
          },
        ]
      : []),
    {
      title: <DropDownFilter name='accessTypeId' firstParam='Full' secondParam='Limited' />,
      dataIndex: 'accessType',
      key: 'accessType',
      render: (_value, row) => {
        const { accessTypeId } = row;
        const { name } = getTypeFromId(accessTypeId);
        const isFullAccess = accessTypeId === USER_ACCESS_TYPES.FULL.id;

        return (
          <div className='d-flex justify-content-center align-items-center'>
            <span className='mr--8'>{name}</span>
            <Tooltip overlay={isFullAccess ? 'Demote to Limited Access' : 'Promote to Full Access'} placement='top'>
              <Button
                variant='link'
                className='p--0'
                onClick={() => {
                  const newAccessTypeId = isFullAccess ? USER_ACCESS_TYPES.LIMITED.id : USER_ACCESS_TYPES.FULL.id;
                  updateUserAccess({
                    body: {
                      id: row.id,
                      accessTypeId: newAccessTypeId,
                    },
                    params: { page },
                  });
                }}
              >
                {isFullAccess ? (
                  <ArrowBottom className='user-remove-icon pointer' width={16} />
                ) : (
                  <ArrowTop className='user-add-icon pointer' width={16} />
                )}
              </Button>
            </Tooltip>
          </div>
        );
      },
    },

    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 80,
      render: (_index, item) => <Actions item={item} />,
    },
  ];
};

export default getColumns;
