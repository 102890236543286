import { LEARNING_COMMUNITY_SECTIONS, USER_COURSE_TYPES } from 'common';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDeleteQuestionMutation, useGetQuestionsListQuery } from 'store/services/learningCommunities/Assessment';

import { getHelpText } from 'constants/sectionsHelpText';

import { useGetQuestionSettingsQuery, useShuffleQuestionsMutation } from 'store/services/testSettings';
import Button from 'style-guide/Button';
import DropdownV2 from 'style-guide/DropdownV2';
import { Help, Plus } from 'style-guide/Icons';
import Switch from 'style-guide/Switch';
import { message } from 'style-guide/ToastMessages';
import Tooltip from 'style-guide/Tooltip';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import DropdownBody from '../DropdownBody';
import EmptyPage from '../EmptyPage';
import Card from './AssessmentCards/OrganizerCard';
import LearnerPreview from './LearnerPreview';
import AssessmentListWrapper from './style/AssessmentListWrapper';

const { organizer } = USER_COURSE_TYPES.userCourseRole;
const { ASSESSMENT } = LEARNING_COMMUNITY_SECTIONS;

const AssessmentList = () => {
  const { id, chapterId, role, testNumber } = useParams();
  const isOrganizer = role === organizer;
  const helpText = getHelpText(ASSESSMENT.value, role);
  const params = useMemo(
    () => (isOrganizer ? { chapterId, courseId: id, forEdit: true } : { chapterId, courseId: id, testNumber }),
    [chapterId, id, isOrganizer, testNumber]
  );
  const { data, isLoading, refetch, isFetching } = useGetQuestionsListQuery(params, {
    refetchOnMountOrArgChange: !!isOrganizer,
  });

  const [deleteQuestion, { data: deletedData, isLoading: deleteIsLoading }] = useDeleteQuestionMutation();
  const hasQuestions = !isLoading && data?.questions?.length;
  const [shuffleQuestions] = useShuffleQuestionsMutation();
  const { data: shuffleData } = useGetQuestionSettingsQuery({ chapterId, courseId: id });

  useEffect(() => {
    if (!deleteIsLoading && deletedData?.success) {
      message.success('The question was successfully deleted.');
      refetch({ chapterId, courseId: id });
    }
  }, [deletedData, deleteIsLoading, refetch, chapterId, id]);

  useEffect(() => {
    if (deletedData?.message) {
      message.success(deletedData.message);
    }
  }, [deletedData?.message]);

  if (isFetching || !hasQuestions) {
    return <EmptyPage isLoading={isFetching} />;
  }

  return (
    <AssessmentListWrapper className='row'>
      <div className='assessment-root col-lg-8 col-10 offset-lg-2 offset-1'>
        {isOrganizer ? (
          <>
            <div className='d-flex justify-content-between'>
              <DropdownV2 overlay={<DropdownBody />}>
                <Button variant='link' prefix={<Plus />}>
                  Add New Questions
                </Button>
              </DropdownV2>
              <div className='d-flex align-items-center mt--16'>
                <Text $variant={2}>Shuffle questions</Text>
                <Switch
                  className='ml--8'
                  $size='small'
                  checked={shuffleData?.shuffleQuestions}
                  onChange={(e) => shuffleQuestions({ chapterId, courseId: id, shuffleQuestions: e.target.checked })}
                />
              </div>
            </div>
            {data.questions.map((el, index) => (
              <Card
                key={el.id}
                index={index}
                assessmentInfo={el}
                deleteQuestion={deleteQuestion}
                chapterId={chapterId}
                courseId={id}
              />
            ))}
          </>
        ) : (
          <>
            <div className='d-flex align-items-center content-title'>
              <Title className='category-name' variant={5}>
                Assessment
              </Title>
              <Tooltip $helpTooltip placement='right' overlay={helpText}>
                <Help className='help-icon' />
              </Tooltip>
            </div>
            <LearnerPreview data={data} courseId={id} chapterId={chapterId} testNumber={testNumber} />
          </>
        )}
      </div>
    </AssessmentListWrapper>
  );
};
export default AssessmentList;
