import classNames from 'classnames';
import { SYSTEM_LIMITS } from 'common';
import Image from 'style-guide/Image';
import Text from 'style-guide/Typography/Text';

const { IMAGE: imageLimits } = SYSTEM_LIMITS;

const MainImageContent = ({ image, widthPlaceholder = 150, heightPlaceholder = 104 }) => (
  <div className={classNames('col-12 d-flex flex-column justify-content-center images-box', { 'has-image': !!image })}>
    {image ? (
      <Image src={image} />
    ) : (
      <div className='d-flex flex-column pointer'>
        <Text $variant={2} $dark={60}>
          Drag & drop
        </Text>
        <Text $variant={2} $dark={60} className='pb--8'>
          an image
        </Text>
        <Text $variant={1} $dark={60}>
          {widthPlaceholder}x{heightPlaceholder}
        </Text>
        <Text $variant={1} $dark={60}>
          Max {imageLimits.size} MB
        </Text>
      </div>
    )}
  </div>
);

export default MainImageContent;
