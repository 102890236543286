import moment from 'moment';
import { Link } from 'react-router-dom';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

export const getEmailTrackingColumns = () => [
  {
    title: <Title variant={5}>Notification</Title>,
    dataIndex: 'text',
    key: 'text',
    width: 500,
    render: (_index, item) => (
      <>
        {item.email && (
          <Text variant={3} paragraph>
            To: {item.email}
          </Text>
        )}
        <Text variant={3} paragraph>
          Subject: {item.subject}
        </Text>
        <Text variant={3} paragraph className='mt--16'>
          Text: {item.text}
        </Text>
        <Text variant={3} paragraph className='mt--16'>
          CTA: <Link to=''>{item?.url}</Link>
        </Text>
      </>
    ),
  },

  {
    title: <Title variant={5}>status</Title>,
    dataIndex: 'Status',
    key: 'status',
  },
  {
    title: <Title variant={5}>updated</Title>,
    dataIndex: 'Updated',
    key: 'updated',
    render: (_index, item) => <Text $variant={2}>{moment(item?.updated).fromNow()}</Text>,
  },
];
